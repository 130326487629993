.right {
    text-align: right;
}
.center {
    text-align: center;
}
h1 {
    font-size: 20px;
}
h2 {
    font-size: 15px;
}
h3 {
    font-size: 12px;
}
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}